import {HeaderBackgroundColorScheme} from '@/enums';
import Button from '@/components/base/elements/Button/Button';
import Anchor from '@/components/shared/Anchor/Anchor';
import {useTranslations} from '@/hooks/useTranslations';

import Header from '../../section/Header/Header';

export default function PlusHeader() {
  const {t} = useTranslations();
  const contactText = t('plus:cta.contact');
  return (
    <Header
      background={HeaderBackgroundColorScheme.TransparentDark}
      className="border-b border-solid border-[rgba(255,255,255,0.2)] bg-[rgba(255,255,255,0.01)]"
      CustomDesktopCtaLinklist={
        <li>
          <Button
            href="#contact-sales"
            componentName="contact-sales"
            size="small"
            mode="dark"
            className="whitespace-nowrap text-white bg-transparent border-solid border-[rgba(255,255,255,1)]"
          >
            {contactText}
          </Button>
        </li>
      }
      CustomMobileCtaLinklist={
        <li>
          <Anchor
            className="whitespace-nowrap hover:underline font-bold text-white underline pr-4"
            href="#contact-sales"
          >
            {contactText}
          </Anchor>
        </li>
      }
    />
  );
}
