import type {PropsWithChildren} from 'react';

import {ColorSchemeEnum} from '@/enums';
import BrochurePageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {usePlusNavLimitPages} from '@/hooks/experiments/usePlusNavLimitPages';

import type {HeaderProps} from '../../section/Header/Header';
import HeaderLimited from '../../section/Header/HeaderLimited';

import PlusHeader from './Header';

type PageLayoutProps = {
  header?: React.ComponentType<HeaderProps>;
  footer?: JSX.Element | null;
  title?: string;
  metaDescription?: string;
};

export default function PageLayout({
  header,
  footer = <Footer colorScheme={ColorSchemeEnum.Dark} />,
  children,
  title,
  metaDescription,
}: PropsWithChildren<PageLayoutProps>) {
  const {isPlusNavLimitPagesExperiment} = usePlusNavLimitPages();
  const defaultHeader = isPlusNavLimitPagesExperiment
    ? HeaderLimited
    : PlusHeader;
  const layoutProps = {
    title,
    metaDescription,
    header: header || defaultHeader,
    footer,
  };
  return <BrochurePageLayout {...layoutProps}>{children}</BrochurePageLayout>;
}
